import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import logo from "../Assets/logo.png";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { MdOutlineMailOutline } from "react-icons/md";
import {
  AiOutlineHome,
  AiOutlineFundProjectionScreen,
  AiOutlineUser,
} from "react-icons/ai";
import { useTranslation, Trans } from "react-i18next";
import '../i18n.js';

const lngs = {
	en: { nativeName: "English" },
	es: { nativeName: "Español" },
}

function NavBar() {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);
  const { i18n } = useTranslation(); 

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  window.addEventListener("scroll", scrollHandler);

  return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="md"
      className={navColour ? "sticky" : "navbar"}
    >
      <Container>
        <Navbar.Brand href="/" className="d-flex">
          <img src={logo} className="img-fluid logo" alt="brand" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => {
            updateExpanded(expand ? false : "expanded");
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto" defaultActiveKey="#home">
            <Nav.Item>
              <Nav.Link as={Link} to="/" onClick={() => updateExpanded(false)}>
                <AiOutlineHome style={{ marginBottom: "2px" }} /> <Trans i18nKey="home" />
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/about"
                onClick={() => updateExpanded(false)}
              >
                <AiOutlineUser style={{ marginBottom: "2px" }} /> <Trans i18nKey="about" />
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/project"
                onClick={() => updateExpanded(false)}
              >
                <AiOutlineFundProjectionScreen
                  style={{ marginBottom: "2px" }}
                />{" "}
                <Trans i18nKey="projects" />
              </Nav.Link>
            </Nav.Item>

            {/* REMOVE WHEN SERVICES IS READY TO GO AND ADD import { AiOutlineLaptop } from "react-icons/ai";
			<Nav.Item>
              <Nav.Link
                as={Link}
                to="/services"
                onClick={() => updateExpanded(false)}
				style={{ display: "" }}
              >
                <AiOutlineLaptop  style={{ marginBottom: "2px" }} />
				<Trans i18nKey="services" />
              </Nav.Link>
            </Nav.Item>*/}

            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/contact"
                onClick={() => updateExpanded(false)}
              >
                <MdOutlineMailOutline style={{ marginBottom: "2px" }} /> <Trans i18nKey="contact" />
              </Nav.Link>
            </Nav.Item>

			<Nav.Item className="fork-btn">
				{Object.keys(lngs).map((lng) => (
				<Button className="fork-btn-inner" type="submit" key={lng} onClick={() => i18n.changeLanguage(lng)} disabled={i18n.resolvedLanguage === lng}>
					{lngs[lng].nativeName}
				</Button>
				))}
            </Nav.Item>
			
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
// <CgGitFork style={{ fontSize: "1.2em" }} />
// <AiFillStar style={{ fontSize: "1.1em" }} />